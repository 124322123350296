@font-face {
  font-family: "NexaRegular"; /*Can be any text*/
  src: local("NexaRegular"), url("./fonts/NexaRegular.otf") format("truetype");
}

@font-face {
  font-family: "NexaLight"; /*Can be any text*/
  src: local("NexaLight"), url("./fonts/NexaLight.otf") format("truetype");
}

@font-face {
  font-family: "NexaBold"; /*Can be any text*/
  src: local("NexaBold"), url("./fonts/NexaBold.otf") format("truetype");
}

@font-face {
  font-family: "NexaHeavy"; /*Can be any text*/
  src: local("NexaHeavy"), url("./fonts/NexaHeavy.ttf") format("truetype");
}
